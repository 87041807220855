import '../scss/main.scss';
import './flyout-navigation';

(function() {
    let contentToggleElements = document.querySelectorAll('.content-toggle');
    
	if (contentToggleElements) {
		contentToggleElements.forEach(element => {
			let items = element.querySelectorAll('.content-toggle-item');
			items.forEach(item => {
				let linkEl = item.querySelector('.content-toggle__card-link');
				linkEl.addEventListener('click', event => {
					if (item.classList.contains('active')) {
						item.classList.remove('active');
						item.parentElement.style.paddingBottom = '1.5rem';
						item.querySelector('.content-toggle__body').style.height = 0;
					} else {
						let bodyHeight = (item.querySelector('.content-toggle__body-inner').offsetHeight + 24) + 'px';
						item.parentElement.style.paddingBottom = bodyHeight;
						item.querySelector('.content-toggle__body').style.height = bodyHeight;
						item.classList.add('active');
					}                
				});
			});
		});
	}
})();
