const menuItemElements = document.querySelectorAll('.main-navigation .has-submenu > a');
const flyoutContainerEl = document.querySelector('.flyout-navigation-container');

let mouseIsOver = false;
let activeFlyoutNavigationEl = null;

menuItemElements.forEach(menuItem => {
    
    menuItem.addEventListener('mouseenter', (event) => {
        const activeflyoutNavigationId = 'nav-' + event.target.getAttribute('rel');
        activeFlyoutNavigationEl = document.getElementById(activeflyoutNavigationId);

        activeFlyoutNavigationEl.classList.add('is-open');
        event.target.classList.add('is-open');

        addMouseEventsToFlyouts(activeFlyoutNavigationEl);
    });

    menuItem.addEventListener('mouseleave', (event) => {
        window.setTimeout(() => {
            if (!mouseIsOver) {
                const activeflyoutNavigationId = 'nav-' + event.target.getAttribute('rel');
                activeFlyoutNavigationEl = document.getElementById(activeflyoutNavigationId);
    
                activeFlyoutNavigationEl.classList.remove('is-open');
                event.target.classList.remove('is-open');
            }
        }, 125);           
    });
    
});

function addMouseEventsToFlyouts(element) {
    element.addEventListener('mouseover', (event) => {
        mouseIsOver = true;
    });

    element.addEventListener('mouseleave', (event) => {
        var e = event.toElement || event.relatedTarget;
        while(e && e.parentNode && e.parentNode != window) {
            if (e.parentNode == this||  e == this) {
                if(e.preventDefault) e.preventDefault();
                return false;
            }
            e = e.parentNode;
        }

        mouseIsOver = false;
        
        closeFlyout();
    }, true);
}

function closeFlyout() {
    activeFlyoutNavigationEl.classList.remove('is-open');
}